/* global $, Application */

/**
 * Application
 */
class Application {
    constructor () {
        this.lastAnimationTime = new Date();
        this.init();
    }

    init () {
        this.initAnimateMainSlider();
        this.initGoogleMaps();
        this.checkIsAnimationTimeout();
        this.initMyGallery();

        $("#medals img").tipsy({fade: true, gravity: 's'});

        $("#medals-slider").lightSlider({
            loop:true,
            keyPress:false,
            item: 7,
            pager: false,
            auto: true,
            pause: 2000,
            pauseOnHover: true,
            controls: false,
            responsive : [
                {
                    breakpoint:992,
                    settings: {
                        item:5
                    }
                },
                 {
                     breakpoint:768,
                     settings: {
                        item:3
                     }
                 },
                {
                    breakpoint:500,
                    settings: {
                        item:2
                    }
                }
            ]
        });

        $("#choose-your-machine-slider").lightSlider({
            item: 5,
            pager: false,
            controls: true,
            responsive : [
                {
                    breakpoint:1024,
                    settings: {
                        item:4
                    }
                },
                {
                    breakpoint:992,
                    settings: {
                        item:3
                    }
                }
            ]
        });


        $("#go-top").on("click", function () {
            $('html,body').animate({
                scrollTop: $("body").offset().top},
            1000);
        });

        $("#main-menu .dropdown").hover(
            function () {
                $(this).find(".dropdown-menu-box").stop().fadeIn(150);
            }, function() {
                $(this).find(".dropdown-menu-box").stop().fadeOut(150);
            }
        );

        $("#main-menu .column.machines a").hover(
            function () {
                $("#main-menu .image-column #image-id-" + $(this).data("imageId")).fadeIn(500);
                $("#main-menu .image-column").stop().fadeIn(500);
            }, function() {
                $("#main-menu .image-column").stop().fadeOut(500);
                $("#main-menu .image-column img.image").fadeOut(500);
            }
        );

        $("#language-select .active").hover(
            function () {
                $(this).find("ul").stop().fadeIn(500);
            }, function() {
                $(this).find("ul").stop().fadeOut(500);
            }
        );

        $("#product-card-nav li").on("click", function () {
            $("#product-card-nav li").removeClass("active");
            $(this).addClass("active");
            let content_id = $(this).data("contentId"),
                width = document.body.clientWidth,
                offset = 0;

            $("#product-card-tab .content-box").slideUp(500);

            setTimeout(function () {
                $("#product-card-tab .content-" + content_id).slideDown(500);

                setTimeout(function () {
                    offset = $("#product-card-tab .content-" + content_id).offset().top;

                    if (width < 992) {
                        offset = parseInt(offset - 67);
                    }

                    $('html,body').animate({
                        scrollTop: offset},
                    1000);
                }, 500)
            }, 500);
        });

        $("#menu-icon").on("click", function () {
            if ($(this).hasClass("open")) {
                $(this).removeClass("tcon-transform open");
                $("html, body").removeClass("no-scroll");

                $("#mobile-menu").animate({
                    left: "-100%"
                }, 500, function () {
                    $("#mobile-menu .second-level").hide();
                })
            } else {
                $("#mobile-menu .first-level").show();
                $(this).addClass("tcon-transform open");
                $("#mobile-menu").animate({
                    left: 0
                }, 500, function () {
                    $("html, body").addClass("no-scroll");
                });
            }
            return false;
        });

        $("#mobile-menu .dropdown").on("click", function () {
            if ($(this).hasClass("machines")) {
                $("#mobile-menu .first-level").fadeOut(500, function () {
                    $("#mobile-menu .second-level-machines").fadeIn(500);
                });
            } else  if ($(this).hasClass("sience-zone")) {
                $("#mobile-menu .first-level").fadeOut(500, function () {
                    $('html,body').scrollTop(0);
                    $("#mobile-menu .second-level-sience-zone").fadeIn(500);
                });
            }
            return false;
        });

        let that = this;

        $("#slider nav ul li button").on("click", function () {
            that.animateMainSlider($(this).parent().data("id"));
            that.lastAnimationTime = new Date();
        });

        $("#product-card-tab .content-4 .video").on("click", function () {
            let html = '<div id="video-modal">' +
                    '<div id="video-modal-content" class="content">' +
                    '<button id="close-video-modal">' +
                    '<img src="images/close.png" alt="close">' +
                    '</button>' +
                    '<div class="video-container">' +
                    '<iframe src="https://www.youtube.com/embed/_S6G-E7VTGU" frameborder="0" allowfullscreen></iframe>' +
                    '</div>' +
                    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et eros suscipit, viverra lacus et, vestibulum sapien.</p>' +
                    '</div>' +
                    '</div>',
                video = $(this).data("video"),
                src = "https://www.youtube.com/embed/" + video;

            setTimeout(function () {
                $("body").addClass("video-modal-open");
            }, 1);

            if ($("#video-modal").length > 0) {
                $("#video-modal iframe").attr("src", src);
                $("#video-modal").fadeIn(1000);
            } else {
                $("body").append(html);
            }
        });

        $("body").click(function(e) {
            if ($("body").hasClass("video-modal-open")) {
                if (!document.getElementById('video-modal-content').contains(e.target)){
                    $("#video-modal").fadeOut(1000);
                    $("body").removeClass("video-modal-open");
                }
            }
        });

        $("body").on("click", "#close-video-modal", function () {
            $("#video-modal").fadeOut(1000);
            $("body").removeClass("video-modal-open");
        })

        /*// select open
        $(".my-select .active").on("click", function () {
            if ($(this).hasClass("close")) {
                $(this).removeClass("close").find("ul").slideDown(500);
            }
        });

        // select close
        $(".my-select li ul li").on("click", function (e) {
            let that = this,
                value = $(this).data("value");

            $(this).closest("ul").slideUp(500);
            setTimeout(function () {

                $(that).parent().parent().addClass("close");
            }, 500);
        });

        // init select
        let html = '<div class="my-select">' +
                        '<ul>' +
                            '<li class="active close">'*/

        $(document).keydown(function(e) {
            let actual_id = $("#my-gallery").data("actualImageId"),
                amount = $("#my-gallery").data("amount");

            switch(e.which) {
                case 37: // left
                    if (actual_id > 1) {
                        that.myGalleryChangeImage(parseInt(actual_id - 1), amount);
                    }
                    break;

                case 39: // right
                    if (actual_id < amount) {
                        that.myGalleryChangeImage(parseInt(actual_id + 1), amount);
                    }
                    break;

                default: return; // exit this handler for other keys
            }
            e.preventDefault(); // prevent the default action (scroll / move caret)
        });
    }

    initMyGallery (id) {
        let that = this;

        if ($("#sample-configurations").length > 0) {
            this.initMyGalleryEvents("#sample-configurations");
        }

        if ($("#sample-gallery").length > 0) {
            this.initMyGalleryEvents("#sample-gallery");
        }

        if ($("#sample-gallery-2").length > 0) {
            this.initMyGalleryEvents("#sample-gallery-2");
        }

        $("body").on("click", "#my-gallery .next", function () {
            let actual_id = $("#my-gallery").data("actualImageId"),
                amount = $("#my-gallery").data("amount");

            if (actual_id < amount) {
                that.myGalleryChangeImage(parseInt(actual_id + 1), amount);
            }

            return false;
        });

        $("body").on("click", "#my-gallery .prev", function () {
            let actual_id = $("#my-gallery").data("actualImageId"),
                amount = $("#my-gallery").data("amount");

            if (actual_id > 1) {
                that.myGalleryChangeImage(parseInt(actual_id - 1), amount);
            }

            return false;
        });

        $("body").click(function(e) {
            if ($("body").hasClass("my-gallery-open")) {
                if (!document.getElementById('my-gallery-content').contains(e.target)){
                    $("#my-gallery").animate({
                        opacity: 0
                    }, 500, function (){
                        $("#my-gallery").remove();
                        $("body").removeClass("my-gallery-open");
                    });
                }
            }
        });

        $("body").on("click", "#close-my-gallery", function () {
            $("#my-gallery").animate({
                opacity: 0
            }, 500, function (){
                $("#my-gallery").remove();
                $("body").removeClass("my-gallery-open");
            });
        });
    }

    initMyGalleryEvents (id) {
        $(id + " .my-gallery").each(function (index) {
            $(this).addClass("my-gallery-" + parseInt(index + 1)).data("imageId", parseInt(index + 1));
        });

        $(id + " .my-gallery").on("click", function () {
            let html = '',
                image_id = $(this).data("imageId"),
                image_src = $(this).attr("href"),
                amount = $(id + " .my-gallery").length,
                prev = "",
                next = "",
                title = $(id + " .my-gallery-" + image_id).data("description");

            if (image_id === 1) {
                prev = " inactive"
            }

            if (image_id === amount) {
                next = " inactive";
            }

            html = '<div id="my-gallery">' +
                '<div class="container">' +
                '<div id="my-gallery-content" class="content">' +
                '<div class="button-container"><button id="close-my-gallery"><img src="images/close.png" alt="close"></button></div>' +
                '<div class="image-container">' +
                '<a href="#" class="prev' + prev + '"><img src="images/arrow-left.png"></a>' +
                '<a href="#" class="next' + next + '"><img src="images/arrow-right.png"></a>' +
                '<img class="main-image" src="' + image_src + '" alt="image">' +
                '</div>' +
                '<p>' + title + '</p>' +
                '</div>' +
                '</div>' +
                '</div>';

            setTimeout(function () {
                $("body").addClass("my-gallery-open");
            }, 1);

            $("body").append(html);

            $("#my-gallery").data("actualImageId", image_id);
            $("#my-gallery").data("amount", amount);
            $("#my-gallery").data("galleryId", id);

            $("#my-gallery").animate({
                opacity: 1
            }, 500);

            return false;
        });
    }

    myGalleryChangeImage (image_id, amount) {
        let gallery_id = $("#my-gallery").data("galleryId"),
            image_src = $(gallery_id + " .my-gallery-" + image_id).attr("href"),
            title = $(gallery_id + " .my-gallery-" + image_id).data("description");

        $("#my-gallery p").text(title);
        $("#my-gallery .main-image").attr("src", image_src);
        $("#my-gallery").data("actualImageId", image_id);

        console.log("gallery_id: " + gallery_id);
        console.log("amount: " + amount);

        console.log("image_id: " + image_id);
        console.log("amount: " + amount);
        console.log("image_src: " + image_src);
        console.log("title: " + title);

        if (image_id === amount) {
            $("#my-gallery .next").addClass("inactive");
        } else {
            $("#my-gallery .next").removeClass("inactive");
        }

        if (image_id === 1) {
            $("#my-gallery .prev").addClass("inactive");
        } else {
            $("#my-gallery .prev").removeClass("inactive");
        }
    }

    checkIsAnimationTimeout () {
        let range = 8,
            last_animation_time = this.lastAnimationTime,
            actual_time = new Date(),
            that = this;

        actual_time = Math.floor(actual_time.getTime() / 1000);
        last_animation_time = Math.floor(last_animation_time.getTime() / 1000);

        if (parseInt(actual_time - range) > last_animation_time) {
            this.animateMainSlider();
            this.lastAnimationTime = new Date();
            this.checkIsAnimationTimeout();
        } else {
            setTimeout(function () {
                that.checkIsAnimationTimeout();
            }, 1000);
        }
    }

    initAnimateMainSlider () {
        $("#slider .slide-1 .background").addClass("animate");
        $("#slider .slide-1 h1").addClass("animate");
        $("#slider .slide-1 .button").addClass("animate");
    }

    animateMainSlider (id) {
        let slide_id = $("#slider").data("slideId"),
            next_slide_id = 0,
            slide_amount = $("#slider .slide").length;

        $("#slider .slide-" + slide_id).fadeOut(500, function () {
            $("#slider .slide-" + slide_id + " .background").removeClass("animate");
            $("#slider .slide-" + slide_id + " h1").removeClass("animate");
            $("#slider .slide-" + slide_id + " button").removeClass("animate");
        });

        if (slide_id < slide_amount) {
            next_slide_id = slide_id + 1;

        } else {
            next_slide_id = 1;
        }

        if (id) {
            next_slide_id = id;
        }

        $("#slider").data("slideId", next_slide_id);

        $("#slider nav ul li").removeClass("active");
        $('#slider nav ul li[data-id="' + next_slide_id + '"]').addClass("active");

        $("#slider .slide-" + next_slide_id).fadeIn(500, function () {
            $("#slider .slide-" + next_slide_id + " .background").addClass("animate");
            $("#slider .slide-" + next_slide_id + " h1").addClass("animate");
            $("#slider .slide-" + next_slide_id + " .button").addClass("animate");
        });
    }

    initGoogleMaps () {
        if ($("#map").length > 0) {
            var uluru = {lat: 50.7861626, lng: 19.0054017};
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 12,
                center: uluru,
                scrollwheel: false
            });

            var marker = new google.maps.Marker({
                position: {lat: 50.7861626, lng: 19.0954017},
                map: map,
                icon: "images/marker.png"
            });

            var marker2 = new google.maps.Marker({
                position: {lat: 50.8252487, lng: 19.109506},
                map: map,
                icon: "images/marker.png"
            });

            var contentString = '<div class="info-window">Kimla, Bałtycka' +
                '</div>';

            var contentString2 = '<div class="info-window">Kimla, Rolnicza' +
                '</div>'

            var infowindow = new google.maps.InfoWindow({
                content: contentString,
                maxWidth: 400,
                disableAutoPan: true
            });

            google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
                return function() {
                    infowindow.setContent(content);
                    infowindow.open(map,marker);
                };
            })(marker,contentString,infowindow));

            google.maps.event.addListener(marker2,'click', (function(marker,content,infowindow){
                return function() {
                    infowindow.setContent(content);
                    infowindow.open(map,marker);
                };
            })(marker2,contentString2,infowindow));

            var styles = [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ];

            map.set('styles', styles);
        }
    }
}

$(document).ready(function () {
    new Application();
});